import { createSelector } from "reselect";
import { GlobalState } from "./globalState";

export const getScottflixTwoSelectedTab = createSelector(
  (state: GlobalState) => state.scottflixTwoState,
  (scottflixTwoState) => {
    return scottflixTwoState.selectedTab;
  }
);

export const getScottflixSelectedShow = createSelector(
  (state: GlobalState) => state.scottflixTwoState,
  (scottflixTwoState) => {
    return scottflixTwoState.selectedShow;
  }
);
