import { TypedAction } from "redoodle";
import { ScottflixNavTab, Show } from "../data/ScotflixTwoData";

export const PREFIX = "@scott-secret-santa//scottflix";

export const selectTab = TypedAction.define(
  `${PREFIX}//selectTab`
)<ScottflixNavTab>();

export const selectShow = TypedAction.define(`${PREFIX}//selectShow`)<
  Show | undefined
>();

export const ScottflixActions = {
  selectTab,
  selectShow,
};
