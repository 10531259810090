import { combineReducers } from "redoodle";
import { createStore, Store } from "redux";
import { aloneReducer } from "../alone/state/AloneReducer";
import { buzzFeedReducer } from "../buzzfeed/state/BuzzFeedReducer";
import { pelotonReducer } from "../peloton/redux/PelotonReducer";
import { scottflixReducer } from "../scottflix/redux/ScottflixReducer";
import { scottflixTwoReducer } from "../scottflixtwo/redux/ScottflixReducer";
import { createInitialGlobalState, GlobalState } from "./globalState";

const rootReducer = combineReducers({
  aloneState: aloneReducer,
  buzzFeedState: buzzFeedReducer,
  pelotonState: pelotonReducer,
  scottflixState: scottflixReducer,
  scottflixTwoState: scottflixTwoReducer,
});

export const store: Store<GlobalState> = createStore(
  rootReducer as any,
  createInitialGlobalState()
);
