import * as React from "react";
import styles from "../../styles/scottflixtwo/FeaturedShow.module.scss";

interface FeaturedShowProps {}

export const FeaturedShow = React.memo(
  function FeaturedShowInternal({}: FeaturedShowProps) {
    return (
      <div className={styles.featuredShowContainer}>
        <div
          className={styles.featuredShowBackground}
          style={{
            backgroundImage: "url(/img/scottflixtwo/familyphoto.jpg)",
          }}
        />
        <div className={styles.featuredShowBackgroundOverlay} />
        <div className={styles.featuredShowMetadataContainer}>
          <div className={styles.logoContainer}>
            <img
              className={styles.logo}
              src={"/img/scottflixtwo/scottflix_series_logo_white.png"}
              alt=""
            />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.subtitleText}>
              Scott Family Secret Santa - The Series
            </div>
            <div className={styles.subtitleIcons}>
              <span className={styles.match}>50% Match</span>
              <span className={styles.year}>DEC 2024</span>
              <span className={styles.rating}>PG-13</span>
              <span className={styles.seasons}>8 Seasons</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
