import { Colors, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScottflixSelectedShow } from "../../state/ScottflixTwoSelectors";
import styles from "../../styles/scottflixtwo/ScottflixHome.module.scss";
import buttonStyles from "../../styles/scottflixtwo/ShowButton.module.scss";
import dialogStyles from "../../styles/scottflixtwo/ShowDetailsDialog.module.scss";
import { SHOW_CATEGORIES, SHOWS } from "../data/ScotflixTwoData";
import { ScottflixActions } from "../redux/ScottflixActions";
import { FeaturedShow } from "./FeaturedShow";
import { ShowButton } from "./ShowButton";
import { ShowsList } from "./ShowsList";

interface ScottflixHomeProps {}

export const ScottflixHome = React.memo(
  function ScottflixHomeInternal({}: ScottflixHomeProps) {
    const dispatch = useDispatch();
    const selectedShow = useSelector(getScottflixSelectedShow);

    const handleCloseDialog = useCallback(() => {
      dispatch(ScottflixActions.selectShow(undefined));
    }, [dispatch]);

    return (
      <div className={styles.scottflixHomeContainer}>
        <FeaturedShow />
        <span className={styles.categoriesList}>
          {SHOW_CATEGORIES.map((category) => (
            <ShowsList
              key={category.key}
              title={category.title}
              shows={SHOWS.filter((show) => category.key === show.category)}
            />
          ))}
        </span>
        <Dialog
          isOpen={selectedShow != null}
          title="test"
          onClose={handleCloseDialog}
          className={dialogStyles.showDetailsDialog}
        >
          <div className={dialogStyles.header}>
            <Icon
              icon={IconNames.CROSS}
              size={36}
              className={dialogStyles.closeButton}
              onClick={handleCloseDialog}
              color={Colors.WHITE}
            />
          </div>
          <div className={dialogStyles.showDetails}>
            <div
              className={classNames(
                dialogStyles.showImageContainer,
                selectedShow?.className
              )}
              style={{
                backgroundImage: `url(${selectedShow?.img})`,
              }}
            >
              <div
                className={dialogStyles.showLogo}
                style={{
                  backgroundImage: `url(${selectedShow?.logoImg})`,
                }}
              />
            </div>
            <div className={dialogStyles.showButtons}>
              <div className={dialogStyles.playButton}>
                <Icon
                  className={dialogStyles.playIcon}
                  icon={IconNames.PLAY}
                  size={30}
                />
                <span className={dialogStyles.playText}>Play</span>
              </div>
              <ShowButton className={styles.button} icon={IconNames.PLUS} />
              <ShowButton
                className={classNames(styles.button, buttonStyles.likeButton)}
                iconClassName={buttonStyles.likeButtonIcon}
                icon="thumbs-up"
              />
            </div>

            <div className={dialogStyles.imageOverlay} />
            <div className={dialogStyles.showInfo}>
              <div>
                <span className={dialogStyles.title}>Cast: </span>
                <span className={dialogStyles.description}>
                  {selectedShow?.cast}
                </span>
              </div>
              <div>
                <span className={dialogStyles.title}>This show is: </span>
                <span className={dialogStyles.description}>
                  {selectedShow?.categories.join(", ")}
                </span>
              </div>
            </div>
            <div className={dialogStyles.showDescription}>
              {selectedShow?.summary.map((summaryItem, index) => (
                <React.Fragment key={index}>
                  {summaryItem}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
);
