import { Icon, IconName } from "@blueprintjs/core";
import classNames from "classnames";
import * as React from "react";
import styles from "../../styles/scottflixtwo/ShowButton.module.scss";

interface ShowPreviewProps {
  icon: IconName;
  className?: string;
  iconClassName?: string;
}

export const ShowButton = React.memo(function ShowButtonInternal({
  icon,
  className,
  iconClassName,
}: ShowPreviewProps) {
  return (
    <div className={classNames(styles.showButton, className)}>
      <Icon className={iconClassName} icon={icon} size={20} />
    </div>
  );
});
