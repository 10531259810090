import * as React from "react";
import styles from "../../styles/scottflixtwo/ShowsList.module.scss";
import { Show } from "../data/ScotflixTwoData";
import { ShowPreview } from "./ShowPreview";

interface ShowsListProps {
  title: string;
  shows: Show[];
}

export const ShowsList = React.memo(function ShowsListInternal({
  title,
  shows,
}: ShowsListProps) {
  return (
    <div className={styles.showsListContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>
        {shows.map((show) => (
          <ShowPreview show={show} key={show.className} />
        ))}
      </div>
    </div>
  );
});
