import { ScottflixNavTab, Show } from "../data/ScotflixTwoData";

export interface ScottflixTwoState {
  selectedTab: ScottflixNavTab;
  selectedShow: Show | undefined;
}

export const INITIAL_SCOTTFLIX_TWO_STATE: ScottflixTwoState = {
  selectedTab: ScottflixNavTab.HOME,
  selectedShow: undefined,
};

export const getInitialState = (): ScottflixTwoState =>
  INITIAL_SCOTTFLIX_TWO_STATE;
