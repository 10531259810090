import { setWith, TypedReducer } from "redoodle";
import { ScottflixNavTab, Show } from "../data/ScotflixTwoData";
import { ScottflixActions } from "./ScottflixActions";
import { getInitialState, ScottflixTwoState } from "./ScottflixTwoState";

export const scottflixTwoReducer = TypedReducer.builder<ScottflixTwoState>()
  .withHandler(
    ScottflixActions.selectTab.TYPE,
    (state: ScottflixTwoState, selectedTab: ScottflixNavTab) => {
      return setWith(state, {
        selectedTab: selectedTab,
      });
    }
  )
  .withHandler(
    ScottflixActions.selectShow.TYPE,
    (state: ScottflixTwoState, selectedShow: Show | undefined) => {
      return setWith(state, {
        selectedShow: selectedShow,
      });
    }
  )
  .withDefaultHandler((_state) => getInitialState())
  .build();
