import classNames from "classnames";
import * as React from "react";
import { isMobile } from "react-device-detect";
import scottflixRulesStyles from "../../styles/scottflixtwo/ScottflixRules.module.scss";
import { SCOTTFLIX_RULES } from "../data/ScotflixTwoData";

declare namespace ScottflixRules {
  export interface Props {}

  export interface State {}
}

// eslint-disable-next-line no-redeclare
export default class ScottflixRules extends React.Component<
  ScottflixRules.Props,
  ScottflixRules.State
> {
  state: ScottflixRules.State = {
    selectedShow: undefined,
  };

  render() {
    return (
      <div
        className={classNames(scottflixRulesStyles.scottflixRules, {
          [scottflixRulesStyles.mobile]: isMobile,
        })}
      >
        <div className={scottflixRulesStyles.rulesBody}>
          {SCOTTFLIX_RULES.map((rule) => (
            <div className={scottflixRulesStyles.ruleContainer} key={rule.rule}>
              <div className={scottflixRulesStyles.ruleImageContainer}>
                <img
                  className={scottflixRulesStyles.ruleImage}
                  src={rule.image}
                  alt=""
                />
              </div>
              <div className={scottflixRulesStyles.ruleText}>
                {rule.heading && (
                  <div className={scottflixRulesStyles.ruleHeader}>
                    {rule.heading}
                  </div>
                )}
                <div className={scottflixRulesStyles.ruleDescription}>
                  {rule.rule}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
