import * as React from "react";
import { useSelector } from "react-redux";
import { getScottflixTwoSelectedTab } from "../../state/ScottflixTwoSelectors";
import styles from "../../styles/scottflixtwo/ScottflixBodyNew.module.scss";
import { ScottflixNavTab } from "../data/ScotflixTwoData";
import { ScottflixHome } from "./ScottflixHome";
import ScottflixRules from "./ScottflixRules";

interface ScottflixBodyNew {}

export const ScottflixBodyNew = React.memo(
  function ScottflixBodyNewInternal({}: ScottflixBodyNew) {
    const selectedTab = useSelector(getScottflixTwoSelectedTab);

    return (
      <div className={styles.scottflixBody}>
        {selectedTab === ScottflixNavTab.HOME ? (
          <ScottflixHome />
        ) : (
          <ScottflixRules />
        )}
      </div>
    );
  }
);
