import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import buttonStyles from "../../styles/scottflixtwo/ShowButton.module.scss";
import styles from "../../styles/scottflixtwo/ShowPreview.module.scss";
import { Show } from "../data/ScotflixTwoData";
import { ScottflixActions } from "../redux/ScottflixActions";
import { ShowButton } from "./ShowButton";

interface ShowPreviewProps {
  show: Show;
}

export const ShowPreview = React.memo(function ShowPreviewInternal({
  show,
}: ShowPreviewProps) {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = React.useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSelectShow = () => {
    dispatch(ScottflixActions.selectShow(show));
  };

  return (
    <div
      className={styles.showPreviewContainer}
      onClick={handleSelectShow}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={styles.showPreview}>
        <img className={styles.showPreviewImage} src={show.previewImg} alt="" />
      </div>
      {isHovering && (
        <div
          className={classNames(styles.showPreview, {
            [styles.hoveredShowPreview]: isHovering,
          })}
        >
          <img
            className={styles.showPreviewImage}
            src={show.previewImg}
            alt=""
          />
          <div className={styles.showMetadata}>
            <div className={styles.buttons}>
              <ShowButton
                className={classNames(styles.button, buttonStyles.playButton)}
                iconClassName={buttonStyles.playButtonIcon}
                icon="play"
              />
              <ShowButton className={styles.button} icon={IconNames.PLUS} />
              <ShowButton
                className={classNames(styles.button, buttonStyles.likeButton)}
                iconClassName={buttonStyles.likeButtonIcon}
                icon="thumbs-up"
              />
              <div className={styles.spacer} />
              <ShowButton
                className={classNames(
                  styles.button,
                  buttonStyles.chevronButton
                )}
                icon="chevron-down"
              />
            </div>
            <div className={styles.seasonInfo}></div>
            <div className={styles.showDescriptions}>
              {show.categories.map((categoryName, index) => {
                return (
                  <span className={styles.category} key={index}>
                    {categoryName}
                    <span className={styles.categoryDivider}>&#9679;</span>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
