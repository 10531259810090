export enum ScottflixNavTab {
  HOME = "HOME",
  RULES = "RULES",
}

export interface Show {
  title: string;
  summary: string[];
  img: string;
  previewImg: string;
  logoImg: string;
  categories: string[];
  className?: string;
  category: string;
  cast: string;
}

export interface ShowCategory {
  title: string;
  key: string;
}

export const SHOW_CATEGORIES: ShowCategory[] = [
  {
    title: "New on Scottflix",
    key: "new",
  },
  {
    title: "Award Winning TV Shows",
    key: "award",
  },
  {
    title: "We Think You'll Love These",
    key: "love",
  },
  {
    title: "Crowd Pleasers",
    key: "crowd",
  },
];

export const SHOWS: Show[] = [
  {
    title: "Girl Boss",
    summary: [
      `Stumbling into the world of deep-sea mining after she realized she liked shiny things and rich people, CEO and aspiring philanthropist Anna Scott navigates a world in which she is not only a boss but also essentially her only employee.`,
      `Drawing design inspiration from bath toys she owned as a child, Anna developed a state-of-the-art and tangle-prone rope with dangly-things attached. What began as a small 3D-printed piece of plastic in a fish tank grew into a slightly larger piece of plastic in a lake, leading Anna into a world of ever growing 3D-printed pieces of plastic.`,
      "Although the viewer may often find themselves bewildered by the purpose of the whole endeavor, Anna’s unapologetic confidence reassures them that whatever it is, it is probably important.",
      `Follow along as Anna converts her family’s second home into her company’s headquarters, randomly goes to the Cook Islands, forges an unconventional path to success, and steadily descends toward Rock Bottom™.`,
    ],
    img: "/img/scottflixtwo/anna_full.jpg",
    previewImg: "/img/scottflixtwo/anna_preview.jpg",
    categories: ["Feel-good", "Ethically-complex"],
    className: "girlBoss",
    category: "award",
    logoImg: "/img/scottflixtwo/anna_logo.png",
    cast: "Anna Scott",
  },
  {
    title: "Workin’ Moms",
    summary: [
      `This comedy-drama follows tattoo tycoon Yan Azdoud as he navigates the challenges of balancing work, parenting, and a demanding sleep schedule.`,
      `His journey is filled with humorous and heartfelt moments as he faces the highs and lows of either becoming a millionaire or losing his job entirely, buying a new house or facing eviction, and upgrading to a state-of-the art electric vehicle or continuing to drive a recently totaled 2010 Subaru. The series dives into themes like long-distance parenting, overweight infants, and the disappointment of voting for the first time in the current political climate.`,
      "Workin’ Moms unapologetically tackles the tough realities and stereotypes surrounding tattoo vending machines, coordinated family Halloween costumes, and bilingual parenting in the modern world.",
    ],
    img: "/img/scottflixtwo/yan_full.jpg",
    previewImg: "/img/scottflixtwo/yan_preview.jpg",
    categories: ["Thought-provoking", "Dark"],
    className: "workinMoms",
    category: "love",
    logoImg: "/img/scottflixtwo/yan_logo.png",
    cast: "Yan Azdoud",
  },

  {
    title: "Breaking Bad",
    summary: [
      `After being forced into an early retirement at the age of 72, mild-mannered former math professor Ridgway Scott has to turn to unconventional means to cover his mounting medical expenses. With his family facing financial ruin, or maybe more accurately mild inconvenience over unnecessary expenses, he must find a way to supplement his meager pension.`,
      `This critically acclaimed drama series follows Scott as he descends deeper into the shadowy world of emeritus academia by traveling the world for “meetings” and establishing a self-named foundation to funnel money towards his personal machinations.`,
    ],
    img: "/img/scottflixtwo/ridg_full.jpg",
    previewImg: "/img/scottflixtwo/ridg_preview.jpg",
    categories: ["Numerical", "Raunchy"],
    className: "breakingBad",
    category: "new",
    logoImg: "/img/scottflixtwo/ridg_logo.png",
    cast: "Ridg Scott",
  },
  {
    title: "Emily in Paris",
    summary: [
      "After landing his dream job in the US, Peter quit for the privilege of earning less than a dollar an hour in Paris. However, this is not a show about surviving on a shoe-string budget in the city of love - a well-timed stock market bump is conveniently subsidizing the standard of living he is accustomed to. While his co-workers were confused when he spent more on a flight for a weekend race than he earned in a whole month, Peter didn’t blink an eye. Alongside his professional life, this series also explores Peter’s personal life - follow along as he plates gourmet TV dinners for one in his apartment while wearing his marathon medal.",
      "Watch as Peter navigates cultural clashes, language barriers, and learning his way around a Michelin-star kitchen. Then watch as he messes everything up, then kind of relearns his way around the kitchen again in this heartwarming tale of a rich, privileged white boy following his dreams.",
    ],
    img: "/img/scottflixtwo/peter_full.jpg",
    previewImg: "/img/scottflixtwo/peter_preview.jpg",
    categories: ["Inspiring", "Underdog Tale"],
    className: "emilyInParis",
    category: "love",
    logoImg: "/img/scottflixtwo/peter_logo.png",
    cast: "Peter Scott",
  },
  {
    title: "Winged Migration",
    summary: [
      `This breathtaking documentary chronicles the incredible journey of a woman as she slowly descends into retirement. Follow Jan as she navigates challenges around the globe, including her first non-work-sponsored travel experience in seven decades. Witness her surprise as she realizes she has to pay for her own hotel!`,
      `Using innovative filming techniques, the documentary offers the viewer an intimate perspective as Jan masters the art of the selfie from various European port cities. Throughout the series, watch as Jan increasingly responds to messages from friends and family with bird pictures, an automatic “Sorry, I am unavailable to answer your message at this time,” or both.`,
    ],
    img: "/img/scottflixtwo/jan_full.jpg",
    previewImg: "/img/scottflixtwo/jan_preview.jpg",
    categories: ["Cinematic", "Birds"],
    className: "wingedMigration",
    category: "new",
    logoImg: "/img/scottflixtwo/jan_logo.png",
    cast: "Jan Englund",
  },
  {
    title: "Gossip Girl",
    summary: [
      `High-profile socialite Jordan O’Neill stars in this irresistible drama set in the glamorous enclave of Cedarville, MI. The seemingly perfect, interconnected lives of Cedarville’s elite are disrupted when an anonymous blogger begins scrutinizing their every move. The blogger reveals salacious gossip about health updates, construction projects, water levels, and further health updates, exposing secrets and igniting tensions.`,
      `Lingering in town past Labor Day under mysterious circumstances, Jordan’s plans for a quiet fall spent watching his dog sleep are quickly derailed as he becomes embroiled in local scandals and amateur sauna construction. Whether it’s delays in drywall installation or accidentally using a dog towel as a human towel, nothing stays quiet for long.`,
      `The show delves into Jordan’s complicated relationships with neighbors, construction crews, and local dogs, while exploring the pressures of maintaining a perfect public image in a world where every move can become public knowledge. Jeopardy night, canine birthday parties, and early 5pm dinners provide the backdrop for this world of intrigue where glamour and gossip collide. This is Cedarville – a place where everyone knows everyone… and everything. XOXO, Gossip Girl.`,
    ],
    img: "/img/scottflixtwo/jordan_full.jpg",
    previewImg: "/img/scottflixtwo/jordan_preview.jpg",
    categories: ["Scandalous", "Binge-worthy"],
    className: "gossipGirl",
    category: "award",
    logoImg: "/img/scottflixtwo/jordan_logo.png",
    cast: "Jordan O'Neill",
  },
  {
    title: "The Last Dance",
    summary: [
      `Dr. Sara Scott stands on the brink of history. After three years of sidestepping the grind of a real career, she’s poised to achieve what no physician before her has dared: the fabled, world-record fellowship four-peat. Follow along as she fills her passport with stamps while emptying her bank account.`,
      `But this year’s competition is fierce. Her husband is closing in, her mom keeps texting "Anna’s child needs a cousin," and she’s afraid that a Cedarville addition-addition might begin if she isn’t around to stop it. Can Sara outmaneuver the skeptics and secure her crown as the reigning champion of reality dodging, or will the dream collapse under the weight of missing her dog?`,
      `A dramatic saga of ambition, avoidance, and the unyielding pursuit of just one more year.`,
    ],
    img: "/img/scottflixtwo/sara_full.jpg",
    previewImg: "/img/scottflixtwo/sara_preview.jpg",
    categories: ["Thrifty", "Tear-jerking", "Pedicure-filled"],
    className: "lastDance",
    category: "award",
    logoImg: "/img/scottflixtwo/sara_logo.png",
    cast: "Sara Scott",
  },
  {
    title: "Wild Babies",
    summary: [
      `This heartwarming and engaging documentary follows Cedarville daycare standout Winn Scott-Azdoud as he navigates challenges in his natural habitat. Watch as he discovers fire, ignores the meaning of the word “gentle,” and teaches his parents about why it’s important to exercise their dog. His brave journey toward toddlerhood is the ultimate coming-of-age adventure.`,
    ],
    img: "/img/scottflixtwo/winn_full_two.jpg",
    previewImg: "/img/scottflixtwo/winn_preview_two.png",
    categories: ["Cute", "Kind of a Handful"],
    className: "wildBabies",
    category: "crowd",
    logoImg: "/img/scottflixtwo/winn_logo.png",
    cast: "Winn ?",
  },
  {
    title: "Bling Empire",
    summary: [
      `This reality show offers a glamorous and drama-filled glimpse into the life of reluctant millionaire Becky "tots" Moore. Follow along as she begrudgingly comes to terms with the fact that she is super rich and navigates the social isolation of only being able to complain about her new-found wealth with other super-rich people at her workplace.`,
      `Bling Empire showcases tots’ increasingly bougie habits, including bimonthly haircuts at exclusive salons and visits with her instagram-famous dentist. As she indulges in her wealth, even her wardrobe gets an upgrade and she purchases increasingly daring colors of Detroit hats, ranging from black to dark gray to even (gasp) dark blue. The series is filled with opulent Survivor-themed parties and lavish shopping sprees at Home Depot’s Black Friday events. Beneath the glitz and the glamor lie personal challenges and dramatic conflicts - most of which could be easily avoided if she just accepted the fact that she had money and used it in a remotely sensible way.`,
      `The mix of jaw-dropping wealth and relatable struggles - like finding space for all of her craft supplies, and determining which flavor of Gu is the best when eaten at body temperature, resonates with audiences, offering a blend of escapism and insight into a unique social world. The show explores heartwarming themes like self-acceptance, baking things, and women’s sports.`,
    ],
    img: "/img/scottflixtwo/tots_full.jpg",
    previewImg: "/img/scottflixtwo/tots_preview.jpg",
    categories: ["Frustrating", "Surprisingly Stylish"],
    className: "blingEmpire",
    category: "love",
    logoImg: "/img/scottflixtwo/tots_logo.png",
    cast: `Becky "tots" Moore`,
  },
  {
    title: "Break Point",
    summary: [`Ball is life.`],
    img: "/img/scottflixtwo/griffin_full.jpg",
    previewImg: "/img/scottflixtwo/griffin_preview.jpg",
    categories: ["Fetch"],
    className: "breakPoint",
    category: "crowd",
    logoImg: "/img/scottflixtwo/griffin_logo.png",
    cast: "Griffin",
  },
  {
    title: "Paw Patrol",
    summary: [
      `Brave dog Nutmeg, led by a tech-savvy young boy, Winn, work together to accomplish high-stakes rescue missions and save the day. This show teaches teamwork, friendship, and how to exercise your dog using an electric bike.`,
    ],
    img: "/img/scottflixtwo/nutmeg_full.jpg",
    previewImg: "/img/scottflixtwo/nutmeg_preview.jpg",
    categories: ["Heartwarming", "Sometimes Neglected"],
    className: "pawPatrol",
    category: "crowd",
    logoImg: "/img/scottflixtwo/nutmeg_logo.png",
    cast: "Nutmeg",
  },
];

export interface Rules {
  rule: string;
  image: string;
  heading?: string;
}

export const SCOTTFLIX_RULES: Rules[] = [
  {
    rule: "Each person will be assigned ONLY ONE other person in the family to whom they will give a present. Steps will be taken to ensure you are not assigned the same person as last year.",
    image: "/img/scottflix/rules-icons/agent.png",
  },
  {
    rule: "Suggested present value is around $50. Maximum spend is $100. Homemade gifts are HIGHLY encouraged (lookin' at you Dad). Small, homemade, virtual/experiential gifts are encouraged this year due to travel constraints.",
    image: "/img/scottflix/rules-icons/money.png",
  },
  {
    heading: "Children",
    rule: "If you would like, you have the option to give 1 small gift (of less than $5) or an inexpensive homemade gift to non assigned secret santa recipients. MUST be small or homemade (ie soap, or food).",
    image: "/img/scottflix/rules-icons/gift.png",
  },
  {
    heading: "Parents",
    rule: "You are allowed to stuff stockings with small gifts. You ARE NOT allowed to otherwise break the rules and give other gifts to non assigned secret santa recipients (lookin' at you Mom). The punishment for breaking this rule is death.",
    image: "/img/scottflix/rules-icons/skull.png",
  },
  {
    rule: "There will be a $25-50 pay to play fee. This money will be donated to an organization decided upon by the winner of the secret santa gift exchange (yes, there will be a winner).",
    image: "/img/scottflix/rules-icons/trophy.png",
  },
  {
    rule: "You will receive your secret santa assignment in a separate email. Gifts will be exchanged sometime around Christmas (exact date TBD).",
    image: "/img/scottflix/rules-icons/email.png",
  },
];
