import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import { AloneHome } from "./alone/AloneHome";
import { BuzzFeedHome } from "./buzzfeed/BuzzFeedHome";
import { Home } from "./home/components/Home";
import { PelotonHome } from "./peloton/PelotonHome";
import { ScottflixTwo } from "./scottflixtwo/ScottflixTwo";
import Scottify from "./scottify/Scottify";
import { TopSecretEighteen } from "./top-secret-eighteen/TopSecretEighteen";
import { TopSecretSeventeen } from "./top-secret-seventeen/TopSecretSeventeen";

export const App = React.memo(function AppInternal() {
  return (
    <div className={styles.scottSecretSantaApp}>
      <BrowserRouter>
        <Routes>
          <Route path="/alone" element={<AloneHome />} />
          <Route path="/buzzfeed" element={<BuzzFeedHome />} />
          <Route path="/peloton" element={<PelotonHome />} />
          <Route path="/scottify" element={<Scottify />} />
          <Route path="/scottflixtwo" element={<ScottflixTwo />} />
          <Route
            path="/top-secret-seventeen"
            element={<TopSecretSeventeen />}
          />
          <Route path="/top-secret-eighteen" element={<TopSecretEighteen />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
});
